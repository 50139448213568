import { OrderProduct } from 'components'
import { OrderProductForm } from 'components/forms'
import { InnerPage } from 'components/layout'
import { TitleIconList } from 'components/lists'
import Spinner from 'components/Spinner/Spinner'
import VendorOrderShipments from 'components/VendorOrderShipment'
import { pick } from 'lodash'
import { get } from 'lodash'
import { bool, func, shape, string } from 'prop-types'
import React, { useEffect, useReducer } from 'react'
import { FiChevronRight, FiLifeBuoy } from 'react-icons/fi'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { getUserType, isAdmin } from 'utils/auth'
import {
  CARB_CERTIFICATE_COUNTRIES,
  MATERIALS,
  ORDER_IMAGE_TYPES,
  USER_TYPES
} from 'utils/constants'
import { dtFormat } from 'utils/dateTime'
import { notifyMappedError } from 'utils/errors'

import { Part, PickupForm } from './components'
import {
  addImages,
  changeProformaStatus,
  init,
  removeFilesCertification,
  removeFilesProforma,
  removeImages,
  updatePickupAddressAndTime,
  updateShipmentData,
  uploadFilesCertification,
  uploadFilesProforma
} from './data/mw'
import { initialState, reducer } from './data/reducer'
import st from './VendorOrderDetail.module.css'

const VendorOrderDetail = ({
  dispatch: dispatchRedux,
  isAdmin,
  userType,
  intl,
  match: {
    params: { orderNumber, vendor }
  }
}) => {
  const shipmentAllowedUserTypes = [
    USER_TYPES.VENDOR,
    USER_TYPES.LOGISTICS,
    USER_TYPES.ADMIN
  ]
  const menuItems = [
    {
      icon: <FiLifeBuoy />,
      text: intl.formatMessage({ id: 'Need Help? Contact Us' })
    },
    {
      icon: <FiChevronRight />,
      path: `/order-list/${vendor}`,
      text: intl.formatMessage({ id: 'Back To List' })
    }
  ]
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    init({
      dispatch,
      dispatchRedux,
      orderNumber,
      vendor
    })
  }, [orderNumber, vendor])

  const handleSubmit =
    ({ shipmentNumber }) =>
    (data, initialFormValues) => {
      updateShipmentData(
        { ...data, shipmentNumber, orderNumber },
        state.lastSubmittedValues[data.productId] || initialFormValues,
        dispatch
      )
    }

  const handleImageAdd = (type, values) => (e) => {
    return new Promise((onDone) => {
      addImages({ imageFiles: e.target.files, type, values }, dispatch, onDone)
    })
  }

  const handleImageRemove =
    (type, values, orderManagementId, productId, lastSubmittedValues) =>
    ({ path, id }) => {
      return new Promise((onDone) => {
        removeImages(
          {
            type,
            values,
            path,
            orderManagementId,
            id,
            productId,
            lastSubmittedValues
          },
          dispatch,
          onDone
        )
      })
    }

  const handleImageError = () => {
    notifyMappedError({
      message: 'Error uploading image'
    })
  }

  const handlePickupFormSumbit = (data) => {
    updatePickupAddressAndTime(
      { orderNumber, vendorId: vendor, formData: data },
      dispatch
    )
  }

  const handleFileUploadProforma = (data) => {
    return new Promise((onDone) => {
      uploadFilesProforma(data, dispatch, onDone)
    })
  }

  const handleFileDeleteProforma = (data) => {
    return new Promise((onDone) => {
      removeFilesProforma(data, dispatch, onDone)
    })
  }

  const handleFileUploadCertification = (data) => {
    return new Promise((onDone) => {
      uploadFilesCertification(data, dispatch, onDone)
    })
  }

  const handleFileDeleteCertification = (data) => {
    return new Promise((onDone) => {
      removeFilesCertification(data, dispatch, onDone)
    })
  }

  const handleProformaAction = (data = {}) => {
    const { productId, setFieldValue, orderManagementId, type } = data
    changeProformaStatus(
      type,
      productId,
      orderManagementId,
      dispatch,
      setFieldValue
    )
  }

  const {
    ordersAreLoading,
    products,
    status,
    ordersLoading,
    imagesLoading,
    lastSubmittedValues,
    pickupFormDataFromContact,
    pickupFormLoading,
    imagesPending,
    proformaFiles,
    proformaFilesPending,
    certificationFiles,
    certificationFilesPending,
    orderPickup,
    pickupFormSubmitting
  } = state

  const partProps = {
    className: st.part,
    ...pick(state, ['country', 'shipBy', 'netToVendor']),
    statusInit: status
  }
  const tableRows = products.map((product) => {
    const {
      comments,
      customization,
      customizationFile,
      imageSrc,
      price,
      productName,
      quantity,
      shippingDate,
      type,
      classifications,
      shipAddress,
      shipmentNumber,
      parcels,
      productId,
      imagesReady,
      imagesPacked,
      orderManagementId,
      proformaFile,
      proformaStatus,
      woodenCrateNeededVendor,
      vendorCode,
      certificationFile,
      woodenCrateValue,
      _id
    } = product
    const countryCode = get(shipAddress, 'country.code', '')
    const categories = classifications.map((clas) => get(clas, 'taxon.name'))
    const isWooden = categories.includes(MATERIALS.WOOD)
    return {
      customization,
      customizationFile,
      formInitialValues: {
        comments,
        productId: _id,
        shippingDate: dtFormat(shippingDate, 'YYYY-MM-DD'),
        [`images${ORDER_IMAGE_TYPES.READY}`]: imagesReady,
        [`images${ORDER_IMAGE_TYPES.PACKED}`]: imagesPacked,
        proformaFile,
        proformaStatus,
        certificationFile,
        parcels
      },
      imageSrc,
      price: price || '',
      productName,
      quantity,
      type,
      shipAddress,
      parcels,
      productId,
      shipmentNumber,
      orderManagementId,
      classifications,
      woodenCrate: !!woodenCrateValue && woodenCrateNeededVendor,
      allowCarbCertification:
        Object.keys(CARB_CERTIFICATE_COUNTRIES).includes(countryCode) &&
        isWooden,
      _id,
      vendorCode
    }
  })

  return (
    <InnerPage heading={orderNumber}>
      <TitleIconList className={st.topList} items={menuItems} />
      <Part {...partProps}>
        {ordersAreLoading ? (
          <Spinner show width={50} />
        ) : (
          <>
            {tableRows.map((tableRow) => {
              const {
                customization,
                customizationFile,
                formInitialValues,
                imageSrc,
                inStock,
                price,
                productName,
                quantity,
                _id,
                type,
                shipAddress,
                shipmentNumber,
                orderManagementId,
                classifications,
                allowCarbCertification,
                woodenCrate,
                vendorCode
              } = tableRow
              return (
                <OrderProduct
                  key={_id}
                  id={_id}
                  className={st.orderProduct}
                  customization={customization}
                  customizationFile={customizationFile}
                  productURL={`/product-detail/${_id}`}
                  imagePath={imageSrc}
                  name={productName}
                  type={type}
                  classifications={classifications}
                  shipAddress={shipAddress}
                  price={price}
                  quantity={quantity}
                  allowCarbCertification={allowCarbCertification}
                  woodenCrate={woodenCrate}
                  vendorCode={vendorCode}
                >
                  <OrderProductForm
                    id={_id}
                    className={st.form}
                    initialValues={formInitialValues}
                    inputFileCntClassName={st.formInputFile}
                    inStock={inStock}
                    onSubmit={handleSubmit({ shipmentNumber })}
                    isLoading={ordersLoading.includes(_id)}
                    onImageAdd={handleImageAdd}
                    onImageRemove={handleImageRemove}
                    onImageError={handleImageError}
                    imagesLoading={imagesLoading}
                    orderManagementId={orderManagementId}
                    imagesPending={imagesPending}
                    lastSubmittedValues={
                      lastSubmittedValues[_id] || formInitialValues
                    }
                    proformaFile={proformaFiles[_id] || ''}
                    proformaFilePending={proformaFilesPending[_id]}
                    certificationFile={certificationFiles[_id] || ''}
                    certificationFilePending={certificationFilesPending[_id]}
                    uploadFiles={{
                      handleFileUploadProforma,
                      handleFileUploadCertification
                    }}
                    deleteFiles={{
                      handleFileDeleteProforma,
                      handleFileDeleteCertification
                    }}
                    canApprove={isAdmin}
                    onProformaAction={handleProformaAction}
                  />
                </OrderProduct>
              )
            })}
            <Row>
              <Col>
                <PickupForm
                  isLoading={pickupFormLoading}
                  onSubmit={handlePickupFormSumbit}
                  pickupFormDataFromContact={{
                    ...pickupFormDataFromContact,
                    companyName: 'Artemest'
                  }}
                  initialValues={{
                    ...pickupFormDataFromContact,
                    ...orderPickup,
                    companyName: 'Artemest'
                  }}
                  intl={intl}
                  pickupFormSubmitting={pickupFormSubmitting}
                />
              </Col>
            </Row>
            {shipmentAllowedUserTypes.includes(userType) && (
              <>
                <Row>
                  <Col>
                    <VendorOrderShipments
                      userType={userType}
                      orderNumber={orderNumber}
                      vendor={vendor}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Part>
    </InnerPage>
  )
}

VendorOrderDetail.propTypes = {
  dispatch: func,
  isAdmin: bool,
  intl: shape({
    formatMessage: func
  }),
  match: shape({
    params: shape({
      orderNumber: string,
      vendor: string
    })
  }),
  location: shape({
    search: string
  }),
  userType: string
}

export default connect(({ authReducer }) => ({
  isAdmin: isAdmin(authReducer),
  userType: getUserType(authReducer)
}))(injectIntl(VendorOrderDetail))
