import { get, head, sum } from 'lodash'
import { GetCollection } from 'redux/actions/collection-action'
import { GetOrdersTh } from 'redux/actions/order-action'
import { getCollection } from 'utils/collections'
import { ORDER_STATUSES } from 'utils/constants'
import { dtFormat } from 'utils/dateTime'
import { getNetToVendorColumnValue } from 'utils/orders'
import { isNumeric } from 'utils/propTypes'
import { formatPrice } from 'utils/utils'

import st from './VendorOrders.module.css'

export const mapActions = {
  GetCollection,
  GetOrdersTh
}

export const mapState = ({
  authReducer: { userType },
  collectionReducer,
  orderReducer
}) => {
  const collection = getCollection({ collectionReducer })
  const { orders, vendorId, ordersFetching, currentPage, limit, pages } =
    orderReducer
  return {
    collectionId: get(collection, '_id', ''),
    ordersFetching,
    currentPage,
    pages,
    limit,
    orders: orders.map((order) => {
      const { number, line_items, display_item_total } = order
      const vendorProducts = line_items.filter((product) =>
        get(product, 'variant_sku')
          .toLowerCase()
          .includes(vendorId.toLowerCase())
      )

      const orderProducts = vendorProducts
        .map((product) => get(product, 'variant_sku'))
        .join(', ')

      const customerExpectedShippingDate = dtFormat(
        head(
          vendorProducts
            .map((product) => new Date(get(product, 'expected_shipping_at')))
            .sort((a, b) => b.getTime() - a.getTime())
        ),
        'DD/MM/YYYY'
      )

      const netToVendor = formatPrice({
        currency: 'EUR',
        price: sum(
          vendorProducts.map((product) => {
            const { cost_price, net_to_vendor } = product

            const priceFromSpree = () => {
              if (!cost_price) {
                return null
              }
              return cost_price === '0.0' ? null : cost_price
            }

            return isNumeric(priceFromSpree())
              ? parseFloat(cost_price)
              : net_to_vendor
          })
        )
      })
      const status = order.state

      const lineItemsVariantIds = order.line_items.map(
        (item) => item.variant_id
      )
      const vendorOrderShipments = order.shipments.filter((shipment) => {
        return shipment.manifest.find((man) =>
          lineItemsVariantIds.includes(man.variant_id)
        )
      })
      const states = vendorOrderShipments.map((shipment) => shipment.state)
      const getStatus = (statuses) => {
        if (order.state === ORDER_STATUSES.RETURNED.value) {
          return ORDER_STATUSES.RETURNED.label
        }
        if (statuses.includes(ORDER_STATUSES.SHIPPED.value)) {
          if (
            statuses.every((status) => status === ORDER_STATUSES.SHIPPED.value)
          ) {
            return ORDER_STATUSES.SHIPPED.label
          } else {
            return ORDER_STATUSES.PARTIAL_SHIPMENT.label
          }
        } else {
          // Not shipped
          if (statuses.includes(ORDER_STATUSES.READY.value)) {
            // Renaming "Ready" to "Working"
            return ORDER_STATUSES.READY.label
          } else {
            return head(statuses)
          }
        }
      }
      const orderStatus = getStatus(states)
      const orderData = {
        cells: [
          { className: st.number, content: number, _id: number },
          { content: orderProducts, _id: 'products' },
          {
            content: customerExpectedShippingDate,
            _id: customerExpectedShippingDate
          },
          { _id: 'status', content: orderStatus, translate: true },
          {
            className: st.price,
            content: getNetToVendorColumnValue({
              orderStatus,
              price: netToVendor
            }),
            _id: 'netPrice'
          },
          {
            className: st.toggler,
            content: '',
            _id: 'toggler'
          }
        ],
        orderDetailPath: `/order-list/${vendorId}/${number}`,
        _id: number,
        orderDetailProps: {
          country: '',
          listPrice: display_item_total,
          netPrice: netToVendor,
          orderNumber: number,
          productsQuantity: vendorProducts.length,
          shipBy: customerExpectedShippingDate,
          shipmentNumber: '',
          status
        }
      }
      return orderData
    }),
    userType
  }
}
