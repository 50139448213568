import cn from 'classnames'
import { OtherProducts, PageNavVendorActions, Spinner } from 'components'
import { Button } from 'components/buttons'
import CopyModal from 'components/copy-prod-data-modal/copy-modal'
import { InputFile } from 'components/formParts'
import { ProductDetailForm } from 'components/forms'
import { InnerPage } from 'components/layout'
import { FloatList, ImagesList } from 'components/lists'
import { Modal } from 'components/modals'
import dayjs from 'dayjs'
import { get, pick } from 'lodash'
import {
  any,
  array,
  arrayOf,
  bool,
  func,
  number,
  shape,
  string
} from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import { readProductsData } from 'redux/service/product-types-service'
import {
  ACCEPTED_FILE_TYPES,
  IMAGE_DIMENSIONS,
  MAIN_IMAGE_DIMENSIONS,
  PRODUCT_ATTACHEMENTS,
  PRODUCT_PROPERTIES,
  PRODUCT_RESOURCES,
  USER_TYPES
} from 'utils/constants'
import { notifyMappedError } from 'utils/errors'
import {
  checkFilesTypes,
  handleImageUploadError,
  handleMainImageUploadError
} from 'utils/files'
import {
  additionalDataFromValues,
  additionalDataMerge,
  additionalDataWoodenCrateOverwrite,
  certificationsFromValues,
  mapForPaginator
} from 'utils/products'
import { mapToOptions } from 'utils/products-data'
import { showNotify } from 'utils/toast'

import { useAuditColors } from './../../utils/useAuditColors'
import { mapActions, mapState } from './config'
import st from './ProductDetail.module.css'

const ProductDetail = ({
  additionalDataOld,
  careInstructions,
  assemblyInstructions,
  changeTypeTh,
  collection,
  collectionId,
  colorOptions,
  copyModalProps,
  dataItems,
  dataItemsOriginal,
  decimalSign,
  deleteFilesAc,
  deleteOtherImageAc,
  deleteProductByIdAc,
  deleteResourceTh,
  designerOptions,
  editProductAc,
  GetCollectionById,
  GetProductById,
  getProductsByCollectionTh,
  getPropertiesAsync,
  GetVendorById,
  history,
  initialValues,
  intl,
  isProductApproved,
  isProductFetched,
  isUserAdmin,
  isUserVendor,
  language,
  mainImagePending,
  mainPhoto,
  match: { params },
  materialOptions,
  onboardingPhoto,
  otherInfo,
  otherPhotos,
  parcels,
  pathOnDeletion,
  photos,
  productPhotosPending,
  productsLeft,
  productsSaved,
  saveMainImageAc,
  saveOtherImagesAc,
  showCommentsInput,
  showCopyModalAc,
  uploadFilesAc,
  userType,
  vendorCollection,
  vendorId,
  vendorName,
  lastUpdatedByAt,
  auditProduct,
  flagAsIncomplete,
  flagAsDiscontinued,
  reactivateProduct,
  auditedOn,
  flaggedAsIncompleteOn,
  flaggedAsDiscontinuedOn,
  priceList,
  finishes,
  technicalDataSheet,
  attachementsPending,
  shippingInOptions,
  lastChanges
}) => {
  const productId = get(params, 'product', '')
  const [categories, setCategories] = useState([])
  const [isCopyModalVisible, setCopyModalVisible] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const productAuditColor = useAuditColors(
    auditedOn,
    flaggedAsIncompleteOn,
    flaggedAsDiscontinuedOn,
    productId
  )

  useEffect(() => {
    readProductsData().then(({ data, error }) => {
      if (data) {
        const pdOptions = mapToOptions({
          lang: language,
          items: data
        })

        setCategories(pdOptions)
      }

      if (error) {
        notifyMappedError(error)
      }
    })
  }, [])

  useEffect(() => {
    GetProductById(productId)
    getPropertiesAsync([
      PRODUCT_PROPERTIES.COLOR,
      PRODUCT_PROPERTIES.DESIGNER,
      PRODUCT_PROPERTIES.MATERIAL
    ])

    innerPageRef.current.scrollIntoView({
      behavior: 'smooth'
    })
  }, [productId])

  useEffect(() => {
    if (!isProductFetched) {
      return
    }

    if (!isProductApproved) {
      const pushPath =
        userType === USER_TYPES.APPROVER
          ? `/approval-detail/${collectionId}`
          : '/'

      history.push(pushPath)

      return
    }

    if (collectionId) {
      getProductsByCollectionTh({
        collectionId,
        mapProducts: mapForPaginator
      })
      GetCollectionById({ id: collectionId })
    }

    if (vendorId) {
      GetVendorById({ vendorId })
    }
  }, [collectionId, isProductApproved, isProductFetched, vendorId])

  const handleCategoryChange = (e) => {
    const typeId = e.target.value

    changeTypeTh({ productId, typeId })
  }

  const handleClickImages = () => {
    history.push({
      pathname: `/image-assets/${userType}/${vendorId}`,
      state: { collection }
    })
  }

  const handleDeleteProduct = () => {
    deleteProductByIdAc({
      id: productId,
      onSuccess: () => {
        history.push(pathOnDeletion)
      }
    })
  }

  const handleMainImageDelete = () => {
    const { MAIN_PHOTO, ONBOARDING_PHOTO } = PRODUCT_RESOURCES
    if (isUserAdmin) {
      deleteResourceTh({
        productId,
        resourceType: mainPhoto ? MAIN_PHOTO : ONBOARDING_PHOTO
      })
    }
  }

  const handleMainImageUpload = (e) => {
    const image = e.target.files[0]

    saveMainImageAc({ image, productId })
  }

  const handleOtherImageClose = ({ path: imagePath }) => {
    deleteOtherImageAc({ imagePath, productId })
  }

  const handleOtherImagesChange = (e) => {
    saveOtherImagesAc({
      imageFiles: e.target.files,
      productId
    })
  }

  const handleOnAudit = () => {
    auditProduct({ productId })
  }

  const handleDownloadPdf = () => {}

  const handleOnFlagAsIncomplete = () => {
    flagAsIncomplete({ productId })
  }

  const handleOnFlagAsDiscontinued = () => {
    flagAsDiscontinued({ productId })
  }

  const handleOnReactivateProduct = () => {
    reactivateProduct({ productId })
  }

  const fileInputsCheck = (files) => {
    const allFilesGood = checkFilesTypes({
      files,
      types: [
        'application/msword',
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/x-rar-compressed',
        'application/x-zip-compressed',
        'application/zip-compressed',
        'application/zip'
      ]
    })

    if (!allFilesGood) {
      showNotify({
        isError: true,
        message: intl.formatMessage({
          id: 'notSupportedFileType'
        })
      })
    }

    return allFilesGood
  }
  const getFileOnChange = (type) => (e) => {
    const { files } = e.target
    const allFilesGood = fileInputsCheck(files)

    if (allFilesGood) {
      uploadFilesAc({
        files,
        type,
        productId
      })
    }
  }

  const fileInputs = () => {
    const data = [
      {
        fileUrl: careInstructions,
        pending: attachementsPending.includes(
          PRODUCT_ATTACHEMENTS.CARE_INSTRUCTIONS
        ),
        titleHd: 'Care Instructions',
        onChange: getFileOnChange(PRODUCT_ATTACHEMENTS.CARE_INSTRUCTIONS),
        onClose: () => {
          deleteFilesAc({
            type: PRODUCT_ATTACHEMENTS.CARE_INSTRUCTIONS,
            productId
          })
        }
      },
      {
        fileUrl: otherInfo,
        pending: attachementsPending.includes(PRODUCT_ATTACHEMENTS.OTHER_INFO),
        titleHd: 'Other Info',
        onChange: getFileOnChange(PRODUCT_ATTACHEMENTS.OTHER_INFO),
        onClose: () => {
          deleteFilesAc({ type: PRODUCT_ATTACHEMENTS.OTHER_INFO, productId })
        }
      },
      {
        fileUrl: priceList,
        pending: attachementsPending.includes(PRODUCT_ATTACHEMENTS.PRICE_LIST),
        titleHd: 'Price List',
        onChange: getFileOnChange(PRODUCT_ATTACHEMENTS.PRICE_LIST),
        onClose: () => {
          deleteFilesAc({ type: PRODUCT_ATTACHEMENTS.PRICE_LIST, productId })
        }
      },
      {
        fileUrl: finishes,
        pending: attachementsPending.includes(PRODUCT_ATTACHEMENTS.FINISHES),
        titleHd: 'Finishes',
        onChange: getFileOnChange(PRODUCT_ATTACHEMENTS.FINISHES),
        onClose: () => {
          deleteFilesAc({ type: PRODUCT_ATTACHEMENTS.FINISHES, productId })
        }
      },
      {
        fileUrl: assemblyInstructions,
        pending: attachementsPending.includes(
          PRODUCT_ATTACHEMENTS.ASSEMBLY_INSTRUCTIONS
        ),
        titleHd: 'Assembly Instructions',
        onChange: getFileOnChange(PRODUCT_ATTACHEMENTS.ASSEMBLY_INSTRUCTIONS),
        onClose: () => {
          deleteFilesAc({
            type: PRODUCT_ATTACHEMENTS.ASSEMBLY_INSTRUCTIONS,
            productId
          })
        }
      },
      {
        fileUrl: technicalDataSheet,
        pending: attachementsPending.includes(
          PRODUCT_ATTACHEMENTS.TECHNICAL_DATA_SHEET
        ),
        titleHd: 'Technical Data Sheet',
        onChange: getFileOnChange(PRODUCT_ATTACHEMENTS.TECHNICAL_DATA_SHEET),
        onClose: () => {
          deleteFilesAc({
            type: PRODUCT_ATTACHEMENTS.TECHNICAL_DATA_SHEET,
            productId
          })
        }
      }
    ]
      .map(({ titleHd, ...field }) => ({
        ...field,
        title: (
          <p className={st.fileInputsTitle}>
            {intl.formatMessage({ id: titleHd })}
            {field.fileUrl ? (
              <a
                className={st.fileInputsTitleA}
                href={field.fileUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                Link
              </a>
            ) : null}
          </p>
        )
      }))
      .map(({ pending, ...content }, id) => ({
        content: <InputFile isLoading={pending} {...content} />,
        id
      }))

    return data
  }

  const highResImages = () => {
    const data = [
      {
        fileUrl: mainPhoto,
        hasSpinner: true,
        label: intl.formatMessage({ id: 'Main image' }),
        spinnerShow: mainImagePending,
        onChange: handleMainImageUpload,
        onClose: handleMainImageDelete
      }
    ].map((content, id) => {
      const { hasSpinner, spinnerShow, ...restContent } = content
      const wrap = (cmp) =>
        hasSpinner ? (
          <Spinner spinnerBg show={spinnerShow}>
            {cmp}
          </Spinner>
        ) : (
          cmp
        )
      const { fileUrl } = restContent
      if (!fileUrl && isUserVendor) {
        return {
          content: null
        }
      }
      return {
        content: wrap(
          <InputFile
            {...restContent}
            accept={ACCEPTED_FILE_TYPES.IMAGES}
            fileDimensions={MAIN_IMAGE_DIMENSIONS}
            height="100%"
            subLabel="1400X1400"
            onError={handleMainImageUploadError}
            disableModify={isUserVendor}
          />
        ),
        id
      }
    })

    return data
  }

  const toggleDeleteModal = () => {
    setDeleteModalVisible(!isDeleteModalVisible)
  }

  const formProps = {
    allowBlock: isUserAdmin,
    allowDelete: isUserAdmin,
    allowTranslate: isUserAdmin,
    categoryOptions: categories,
    className: st.form,
    colorOptions,
    dataItems,
    decimalSign,
    designerOptions,
    shippingInOptions,
    initialValues,
    lang: language,
    materialOptions,
    showCommentsInput,
    userType,
    parcels,
    vendorId: vendorId,
    productId,
    onCategoryChange: handleCategoryChange,
    onCopyTo: () => {
      setCopyModalVisible(true)
      showCopyModalAc(true)
    },
    onDelete: toggleDeleteModal,
    onAudit: handleOnAudit,
    onDownloadPdf: handleDownloadPdf,
    onFlagAsIncomplete: handleOnFlagAsIncomplete,
    onFlagAsDiscontinued: handleOnFlagAsDiscontinued,
    flaggedAsDiscontinuedOn,
    onReactivateProduct: handleOnReactivateProduct,
    onSubmit: async (values) => {
      const {
        canBeCustomized,
        markForTranslation,
        productName,
        remadeDays,
        stockQuantity,
        parcels
      } = values
      const additionalData = additionalDataMerge({
        additionalDataNew: additionalDataFromValues({
          language,
          values
        }),
        additionalDataOld,
        dataItems: dataItemsOriginal,
        language
      }).map(additionalDataWoodenCrateOverwrite(values))

      const toSave = {
        additionalData,
        careInstructions,
        certifications: certificationsFromValues(values),
        ...pick(values, [
          'comments',
          'dedicatedCourier',
          'description',
          'designer',
          'isCITES',
          'mainColor',
          'material',
          'secondaryMaterial',
          'needsAssembly',
          'retailPrice',
          'shippingIn',
          'suitableForContract',
          'vendorCode',
          'internalUseOnly',
          'weight',
          'wholesalePrice',
          'retailUSD',
          'retailGBP',
          'packagingTime',
          'yearIntoMarket',
          'blocked'
        ]),
        dimensions: pick(values, ['D', 'H', 'W']),
        isCustomizable: canBeCustomized,
        mainPhoto,
        name: productName,
        stockQuantity,
        otherInfo,
        parcels: parcels,
        photos,
        readyToTranslate: markForTranslation,
        remandeIn: remadeDays,
        saved: true
      }
      editProductAc({
        formatMessage: intl.formatMessage,
        id: productId,
        product: toSave
      })
    }
  }

  const innerPageRef = useRef()

  const auditDates = [
    { label: 'Audited on:', date: auditedOn },
    {
      label: 'Flagged as incomplete on:',
      date: flaggedAsIncompleteOn
    },
    {
      label: 'Flagged as discontinued as on:',
      date: flaggedAsDiscontinuedOn
    }
  ]
    .sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
    .reverse()

  const auditVisibleFor = [USER_TYPES.ADMIN, USER_TYPES.LOGISTICS]
  return (
    <InnerPage
      childrenClassName={st.children}
      className={st.page}
      heading={vendorName}
      ref={innerPageRef}
    >
      {/* Show border only if the color is calculated and user is admin */}
      {productAuditColor && auditVisibleFor.includes(userType) && (
        <>
          <div
            id="auditBorder"
            style={{ borderColor: productAuditColor }}
            className={st.border}
          />
          <UncontrolledTooltip placement="right" target="auditBorder">
            {auditDates.map((audit) => {
              return (
                audit.date && (
                  <p>
                    {audit.label} <br />
                    {dayjs(audit.date).format('YYYY-MM-DD HH:mm')}
                  </p>
                )
              )
            })}
          </UncontrolledTooltip>
        </>
      )}
      <PageNavVendorActions
        className={st.actionsTop}
        collectionId={collectionId}
        userType={userType}
        vendorId={vendorId}
        onClickImages={handleClickImages}
        lastUpdatedByAt={lastUpdatedByAt}
        lastChanges={lastChanges}
        isProduct
      />
      {isProductFetched ? (
        <ProductDetailForm
          {...formProps}
          fileInputs={<FloatList items={fileInputs()} />}
          highResImagesInputs={<FloatList items={highResImages()} />}
          onboardingImage={
            <Spinner spinnerBg show={mainImagePending}>
              <InputFile
                accept={ACCEPTED_FILE_TYPES.IMAGES}
                fileDimensions={IMAGE_DIMENSIONS}
                fileUrl={mainPhoto || onboardingPhoto}
                height="100%"
                label={intl.formatMessage({ id: 'Onboarding image' })}
                subLabel="1400X1400"
                width="auto"
                onChange={handleMainImageUpload}
                onClose={handleMainImageDelete}
                onError={handleImageUploadError}
                disableModify={isUserVendor}
              />
            </Spinner>
          }
          otherImages={
            <div>
              <h2 className="mod1">
                <FormattedMessage id="Other images" />
              </h2>
              <Row className={cn('rowWide', st.otherImagesWrap)}>
                <Col sm={3} xl={12}>
                  {isUserAdmin && (
                    <InputFile
                      multiple
                      accept={ACCEPTED_FILE_TYPES.IMAGES}
                      fileDimensions={IMAGE_DIMENSIONS}
                      height="100%"
                      label={intl.formatMessage({ id: 'Other images' })}
                      subLabel="1400X1400"
                      onChange={handleOtherImagesChange}
                      onError={handleImageUploadError}
                    />
                  )}
                </Col>
                {otherPhotos.length > 0 ? (
                  <Col sm={9} xl={12}>
                    <ImagesList
                      className={st.otherImages}
                      colsWidth={{ lg: 2, md: 3, sm: 6 }}
                      items={otherPhotos.map((photo) => {
                        return { ...photo, disabledClose: isUserVendor }
                      })}
                      pendingIds={productPhotosPending}
                      onClose={handleOtherImageClose}
                      square
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          }
        />
      ) : null}
      {vendorCollection ? (
        <>
          <Col className="pageTitle" tag="h2">
            <FormattedMessage id="In this collection" />
          </Col>
          <OtherProducts
            className={st.otherProducts}
            productsLeft={productsLeft}
            productsSaved={productsSaved}
          />
        </>
      ) : null}
      <Modal
        footer={
          <>
            <Button onClick={toggleDeleteModal}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button danger onClick={handleDeleteProduct}>
              <FormattedMessage id="Delete" />
            </Button>
          </>
        }
        isOpen={isDeleteModalVisible}
        title={intl.formatMessage({
          id: 'Are you sure to delete product'
        })}
        toggle={toggleDeleteModal}
      />
      {isCopyModalVisible && <CopyModal {...copyModalProps} />}
    </InnerPage>
  )
}

ProductDetail.propTypes = {
  additionalDataOld: shape({}),
  auditedOn: string,
  auditProduct: func,
  flaggedAsIncompleteOn: string,
  flagAsIncomplete: func,
  flagAsDiscontinued: func,
  flaggedAsDiscontinuedOn: string,
  reactivateProduct: func,
  blocked: bool,
  careInstructions: shape({}),
  careInstructionsPending: bool,
  changeTypeTh: func,
  collection: shape({}),
  collectionId: string,
  colorOptions: shape({}),
  copyModalProps: shape({}),
  dataItems: array,
  dataItemsOriginal: array,
  decimalSign: bool,
  deleteFilesAc: func,
  deleteOtherImageAc: func,
  deleteProductByIdAc: func,
  deleteResourceTh: func,
  designerOptions: shape({}),
  shippingInOptions: shape({}),
  editProductAc: func,
  GetCollectionById: func,
  GetProductById: func,
  getProductsByCollectionTh: func,
  intl: shape({
    formatMessage: func
  }),
  getPropertiesAsync: func,
  GetVendorById: func,
  history: shape({}),
  initialValues: shape({}),
  isProductApproved: bool,
  isProductFetched: bool,
  isUserAdmin: bool,
  isUserVendor: bool,
  language: string,
  mainImagePending: bool,
  mainPhoto: string,
  match: shape({
    params: string
  }),
  materialOptions: shape({}),
  onboardingPhoto: string,
  otherInfo: shape({}),
  otherInfoPending: bool,
  otherPhotos: array,
  parcelIndex: number,
  pathOnDeletion: string,
  parcels: array,
  photos: array,
  productPhotosPending: bool,
  productsLeft: number,
  productsSaved: number,
  saveMainImageAc: func,
  saveOtherImagesAc: func,
  showCommentsInput: func,
  showCopyModalAc: func,
  uploadFilesAc: func,
  userType: string,
  vendorCollection: shape({}),
  vendorId: string,
  vendorName: string,
  lastUpdatedByAt: shape({
    [string]: shape({
      date: string,
      email: string
    })
  }),
  priceList: string,
  finishes: string,
  assemblyInstructions: string,
  technicalDataSheet: string,
  category: string,
  attachementsPending: arrayOf(string),
  lastChanges: arrayOf(
    shape({
      userType: string,
      email: string,
      date: string,
      changes: arrayOf(
        shape({
          field: string,
          from: any,
          to: any
        })
      )
    })
  )
}
export default injectIntl(connect(mapState, mapActions)(ProductDetail))
