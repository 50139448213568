import { get, isArray, isEqual, keyBy, pick } from 'lodash'
import { CURRENCIES, FAILED_STAUTSES } from 'utils/constants'
import { dtFormat } from 'utils/dateTime'
import { checkFilesTypes } from 'utils/files'
import { pricesByCurrency } from 'utils/products'
import { isNumeric } from 'utils/propTypes'
import { showNotify } from 'utils/toast'
import { formatPrice } from 'utils/utils'

export const getProductsByShipments = (order = {}) => {
  const { line_items, shipments } = order
  const lineItemsByVariantId = keyBy(line_items, 'variant_id')

  return shipments.reduce((acc, shipment) => {
    const { manifest, number, state } = shipment

    manifest.forEach(({ variant_id }) => {
      const {
        customization_summary_it = '',
        expected_shipping_at,
        product = {},
        productData = {},
        quantity,
        variant = {}
      } = get(lineItemsByVariantId, `${variant_id}`) || {}
      const { price } = product
      const productMainPhoto = get(product, 'main_image', '')
      const { in_stock, is_backorderable } = variant
      const productDataMerged = {
        ...productData,
        name: get(product, 'name'),
        mainPhoto: get(product, 'main_image'),
        classifications: get(product, 'classifications'),
        onboardingPhoto: productMainPhoto,
        _id: get(product, 'sku', ''),
        comments: get(productData, 'comments', '')
      }
      acc.push({
        customization: customization_summary_it || '',
        inStock: in_stock || false,
        isBackorderable: is_backorderable,
        price,
        product: productDataMerged,
        quantity,
        shipmentNumber: number,
        shippingDate: expected_shipping_at,
        status: state
      })
    })

    return acc
  }, [])
}

export const mapOrders = (orders = []) => {
  return orders.map((order) => {
    const {
      completed_at,
      display_item_total,
      line_items,
      number,
      shipment_state
    } = order
    const { products, shipBy } = line_items.reduce(
      (acc, lineItem) => {
        const { products, shipBy } = acc
        const { expected_shipping_at, productData, variant_sku } = lineItem
        const {
          mainPhoto,
          name: productName,
          onboardingPhoto
        } = productData || {}
        const date = dtFormat(expected_shipping_at)

        return {
          products: {
            ...products,
            [variant_sku]: {
              imageAlt: productName || '',
              imagePath: mainPhoto || onboardingPhoto || 'https://fakeimg.pl/50'
            }
          },
          shipBy: date > shipBy ? date : shipBy
        }
      },
      {
        product: '',
        products: {},
        shipBy: ''
      }
    )

    return {
      date: dtFormat(completed_at),
      orderNumber: number,
      products,
      shipBy,
      status: shipment_state,
      value: display_item_total
    }
  })
}

export const mapProductsForOrder = (products = []) =>
  products.map((product) => {
    const { mainPhoto, onboardingPhoto, _id } = product || {}
    const currency = 'EUR'
    const prices = pricesByCurrency({ currency, product })

    return {
      imageAlt: '',
      imageSrc: mainPhoto || onboardingPhoto || 'https://fakeimg.pl/120',
      price: formatPrice({
        currency,
        price: prices.final
      }),
      _id,
      ...pick(product, ['name', 'status'])
    }
  })

/**
 * @param {Object} config
 * @param {String} config.currency
 * @param {String} config.priceType
 * @param {Array} config.productsList
 */
export const priceCount = (config = {}) => {
  const {
    currency = CURRENCIES.EUR,
    priceType = 'wholesale',
    productsList = []
  } = config

  const sumPrice = productsList.reduce((sum, product) => {
    const price = +get(product, `pricing.[${currency}].[${priceType}]`) || 0
    const quantity = get(product, 'quantity') || 0

    return sum + price * quantity
  }, 0)

  return formatPrice({
    currency,
    price: sumPrice
  })
}

export const shipmentsForOrderDashboard = (orders = []) => {
  return orders.reduce((acc, order) => {
    const { display_item_total, display_total, line_items, shipments } = order
    const lineItemsByVariantId = keyBy(line_items, 'variant_id')
    const mapped = shipments.map((shipment) => {
      const { id, manifest, number, order_id, shipmentAddress, state } =
        shipment
      const { priceNetToVendor, products, productsList, shipBy } =
        manifest.reduce(
          (res, { variant_id }) => {
            const { products, productsList, shipBy } = res
            const {
              cost_price,
              expected_shipping_at,
              net_to_vendor,
              productData,
              quantity,
              variant_sku
            } = get(lineItemsByVariantId, `${variant_id}`) || {}
            const priceValue = isNumeric(cost_price)
              ? parseFloat(cost_price)
              : net_to_vendor
            return {
              ...res,
              priceNetToVendor: res.priceNetToVendor + priceValue,
              products: `${products}${products ? ', ' : ''}${variant_sku}`,
              productsList: [
                ...productsList,
                {
                  ...productData,
                  quantity: quantity || 0
                }
              ],
              shipBy:
                expected_shipping_at > shipBy ? expected_shipping_at : shipBy
            }
          },
          {
            priceNetToVendor: 0,
            products: '',
            productsList: [],
            shipBy: ''
          }
        )

      return {
        country: get(shipmentAddress, 'country.name', ''),
        listPrice: display_item_total,
        netPrice: display_total,
        number,
        order: order_id,
        priceNetToVendor: formatPrice({
          currency: 'EUR',
          price: priceNetToVendor
        }),
        products,
        productsList,
        shipBy: dtFormat(shipBy, 'DD/MM/YYYY'),
        state,
        status: order.state,
        _id: id
      }
    })

    return [...acc, ...mapped]
  }, [])
}

/**
 * If order has one of the failed statuses then we display that status
 * In any other case we display order's price
 * @param {Object} config
 * @param {String} config.orderStatus
 * @param {String} config.price
 * @returns {String}
 */
export const getNetToVendorColumnValue = ({ orderStatus, price }) => {
  const statusesToShow = Object.keys(FAILED_STAUTSES)
  return statusesToShow.includes(orderStatus)
    ? FAILED_STAUTSES[orderStatus]
    : price
}

export const getChangedValues = (values, initialValues) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = isArray(value)
      ? !isEqual(initialValues[key], value)
      : initialValues[key] !== value

    if (hasChanged) {
      acc[key] = value
    }

    return acc
  }, {})
}

export const fileInputsCheck = (files) => {
  const allFilesGood = checkFilesTypes({
    files,
    types: [
      'application/msword',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ]
  })

  if (!allFilesGood) {
    showNotify({
      isError: true,
      message: 'Please upload PDF, DOC/DOCX files only'
    })
  }

  return allFilesGood
}
