import { get, sum } from 'lodash'
import {
  FAKE_IMAGES,
  ORDER_PROFORMA_STATUSES,
  WOODEN_CRATE
} from 'utils/constants'
import { dtFormat } from 'utils/dateTime'
import { isNumeric } from 'utils/propTypes'
import { formatPrice } from 'utils/utils'

import { orderCertificationAdd, orderProformaAdd } from './actions'

export const mapOrderInit = (order = {}, vendor, vendorData, dispatch) => {
  const {
    display_item_total,
    display_total,
    ship_address,
    state,
    orderManagementPickUpLocation
  } = order
  const orderItems = order.line_items.filter(
    (item) => get(item, 'product.sku').split('-')[0] === vendor
  )
  const vendorOrderManagementPickUpLocation =
    orderManagementPickUpLocation.find(
      (pickupLocation) => pickupLocation?.vendorId === vendor
    )

  const shipBy = orderItems.reduce((maxDate, lineItem) => {
    const { expected_shipping_at } = lineItem
    return expected_shipping_at > maxDate ? expected_shipping_at : maxDate
  }, '')

  const getWoodenCrateValue = (product) =>
    get(
      get(product, 'productData.additionalData').find(
        (item) => item.key === WOODEN_CRATE.en
      ),
      'value',
      0
    )

  const netToVendor = formatPrice({
    price: sum(
      orderItems.map((p) => {
        const { cost_price, quantity } = p
        const priceFromSpree = () => {
          if (!cost_price || cost_price === '0.0') {
            return null
          }
          return isNumeric(cost_price) ? parseFloat(cost_price) : null
        }
        const netToVendorPrice =
          priceFromSpree() ||
          (get(p, 'productData.pricing.EUR.wholesale') +
            getWoodenCrateValue(p)) *
            quantity
        return netToVendorPrice
      })
    )
  })

  return {
    country: get(ship_address, 'country.name', ''),
    listPrice: display_item_total,
    netPrice: display_total,
    products: orderItems.map((pbs) => {
      const {
        customization_summary_it,
        customization_pdf,
        price,
        product = {},
        quantity,
        shipmentNumber,
        shippingDate
      } = pbs
      const { name, onboardingPhoto, classifications, sku } = product || {}
      const type = get(pbs, 'productData.type')
      const mainPhoto = get(product, 'main_image', {})
      const productOrderData =
        get(order, 'orderManagementData', []).find(
          (data) => data.productSKU === sku
        ) || {}
      dispatch(
        orderCertificationAdd({
          productId: sku,
          url: get(productOrderData, 'cardbCertification', '')
        })
      )
      dispatch(
        orderProformaAdd({
          productId: sku,
          url: get(productOrderData, 'purchaseOrderFile', '')
        })
      )
      return {
        comments: get(productOrderData, 'comments', ''),
        customization: customization_summary_it,
        customizationFile: customization_pdf,
        classifications,
        imageAlt: name,
        imageSrc: mainPhoto || onboardingPhoto || FAKE_IMAGES.ORDER_PRODUCT,
        packageDimensions: get(product, 'dimensions') || {},
        material: get(product, 'material', ''),
        price,
        productName: name,
        quantity,
        parcels: get(productOrderData, 'parcels', []),
        certificationFile: get(productOrderData, 'cardbCertification', ''),
        proformaFile: get(productOrderData, 'purchaseOrderFile', ''),
        proformaStatus: get(
          productOrderData,
          'purchaseOrderStatus',
          ORDER_PROFORMA_STATUSES.PENDING
        ),
        shipmentNumber,
        shippingDate: get(productOrderData, 'vendorShippingDate', shippingDate),
        imagesReady: get(productOrderData, 'readyPhoto', []),
        imagesPacked: get(productOrderData, 'packagePhoto', []),
        orderManagementId: get(productOrderData, '_id', ''),
        shipAddress: get(order, 'ship_address'),
        type,
        vendorCode: get(pbs, 'productData.vendorCode'),
        woodenCrateValue: getWoodenCrateValue(pbs),
        woodenCrateNeededVendor: vendorData.woodenCrateNeeded,
        _id: sku
      }
    }),
    netToVendor,
    shipBy: dtFormat(shipBy, 'DD/MM/YYYY'),
    status: state,
    orderPickup: vendorOrderManagementPickUpLocation && {
      ...vendorOrderManagementPickUpLocation,
      address2: vendorOrderManagementPickUpLocation.address2 || ''
    }
  }
}
