import axios from 'axios'
import { PageNav, Spinner } from 'components'
import { Button } from 'components/buttons'
import { InnerPage } from 'components/layout'
import { func, shape, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import apiUrl from 'redux/service/apiUrl'
import { getUserType } from 'utils/auth'
import { vendorNavItems } from 'utils/utils'

import { mapState } from './config'
import st from './SapOrders.module.css'

const SapOrders = ({
  match: {
    params: { vendorId }
  },
  location: { pathname },
  intl,
  authReducer
}) => {
  const [ordersNotOnSap, setOrdersNotOnSap] = useState()
  const [orderNumber, setOrderNumber] = useState()
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const [createOrderInProgress, setCreateOrderInProgress] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSuccess(null)
    setError(null)
    setCreateOrderInProgress(true)

    try {
      await axios.post(`${apiUrl}/api/sap/order/${orderNumber}`, undefined, {
        headers: { token: cookie.load('artemest') }
      })

      setSuccess(`Order ${orderNumber} has been created on SAP`)
      setOrderNumber(null)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          setError(`Error: ${err.response.data ?? err.response.status}`)
        } else if (err.request) {
          setError('Error: No response received from the server.')
        } else {
          setError('Error setting up the request:', err.message)
        }
      } else {
        setError('An unknown error occurred:', err)
      }
    }

    setCreateOrderInProgress(false)
  }

  const getOrdersNotOnSap = async () => {
    return axios.get(`${apiUrl}/api/sap/check-spree-orders`, {
      headers: { token: cookie.load('artemest') }
    })
  }

  useEffect(() => {
    getOrdersNotOnSap().then((res) => {
      setOrdersNotOnSap(res.data)
    })
  }, [])

  return (
    <InnerPage heading={'SAP Orders'}>
      <PageNav
        className={st.pageNav}
        items={vendorNavItems({
          id: vendorId,
          intl,
          path: pathname,
          userType: getUserType(authReducer)
        })}
      />
      <div>
        {!ordersNotOnSap
          ? 'Loading...'
          : ordersNotOnSap && ordersNotOnSap.length === 0
          ? 'All Spree orders from the last 30 days have been created correctly on SAP. Please note that orders placed today after midnight are not included in this list.'
          : 'The Spree orders from the past 30 days listed below were not successfully created in SAP. Please note that orders placed today after midnight are not included in this list:'}
        {ordersNotOnSap?.map((order) => (
          <a
            key={order.number}
            className={st.orderLink}
            href={`https://artemest.com/admin/orders/${order.number}/edit`}
            target="_blank"
            rel="noreferrer"
          >
            {order.number}
          </a>
        ))}
      </div>
      <hr />
      After resolving the issues in the order (e.g., incorrect zip code length,
      discontinued products, etc.), please wait a couple of hours before
      attempting to resend it to SAP.
      <br />
      Enter the Order Number in the field below and click &quot;CREATE ON
      SAP&quot;.
      <br />
      <br />
      <form className={st.form} onSubmit={handleSubmit}>
        <label>
          <input
            type="text"
            className={st.input}
            value={orderNumber}
            placeholder="Spree Order Number"
            onChange={(e) => setOrderNumber(e.target.value)}
            required
          />
        </label>
        <Button
          type="submit"
          disabled={createOrderInProgress}
          className={st.button}
        >
          {createOrderInProgress ? (
            <Spinner
              color="--main-bg-light"
              width={43}
              length={3}
              size={14}
              show
            />
          ) : (
            'Create on SAP'
          )}
        </Button>
      </form>
      {error && <div className={st.error}>{error}</div>}
      {success && <div className={st.success}>{success}</div>}
    </InnerPage>
  )
}

SapOrders.propTypes = {
  intl: shape({
    formatMessage: func
  }),
  match: shape({
    params: shape({
      vendorId: string
    })
  }),
  location: shape({
    pathname: string
  }),
  authReducer: shape({})
}

export default connect(mapState)(injectIntl(SapOrders))
